.new-invoice {
    .new-invoice-title {
        margin-left: 5%;
        margin-top: 0px;
        padding: 25px;
        padding-bottom: 10px;
    }

    .new-invoice-client-info {
        background-color: white;

        .client-operator {
            display: flex;
            justify-content: space-between;
            margin-left: 3%;
            width: 90%;
        }
        .dicky {
            display: flex;
            margin-left: 3%;
            margin-bottom: 40px;
            background-color: white;
            justify-content: space-between;
            font-size: 10px;
        }
    }

    .invoice-total {
        display: flex;
        width: 94%;
        background-color: white;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        margin-left: 3%;
        font-size: 10px;

        .invLabMid {
            width: 100%;
            display: flex;

            .midDown {
                display: flex;
                margin-left: 3%;
                margin-top: 50px;
                width: 100%;
                position: relative;
                height: 110pt;
            }
        }

        .add-button {
            background-color: white;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .plusButton {
                background-color: #4fa8f5;
                border-radius: 22px;
                border: 1px solid #32349e;
                display: inline-block;
                cursor: pointer;
                color: #ffffff;
                padding: 1px 10px;
                height: 30pt;
                align-self: center;
                transform: translateX(-50pt);
            }
            .plusButton:hover {
                background-color: #395ca7;
            }
            .plusButton:active {
                position: relative;
                top: 1px;
            }

            .psign {
                height: 30px;
                align-self: center;
                margin-top: 5px;
            }
        }
    }

    .new-total {
        float: right;
        font-size: 20pt;
        margin-right: 10%;
        padding: 15pt;
    }

    .options {
        width: 88%;
        background-color: white;
        font-size: 20pt;
        font-weight: 800;
        margin-top: 50pt;
        padding: 10pt;
        margin-left: 3%;
        border-radius: 10px;
    }

    .billButtons {
        padding: 60px 35px;

        .prevButton {
            background-color: #172f43;
            border-radius: 10px;
            border: 1px solid#172f43;
            cursor: pointer;
            color: #ffffff;
            padding: 13px 15px;
            font-size: 20px;
            width: 10%;
            text-align: center;
            line-height: 35pt;
            margin-left: 60%;
            text-decoration: none;
        }
        .prevButton:hover {
            background-color: #172f43;
        }
        .prevButton:active {
            position: relative;
            top: 1px;
        }

        .genButton {
            background-color: #3f8acc;
            border-radius: 10px;
            border: 1px solid #32349e;
            cursor: pointer;
            color: #ffffff;
            padding: 1px 16px;
            height: 35pt;
            align-self: center;
            font-size: 20px;
            width: 18%;
            margin-right: 50px;
            text-align: center;
            float: right;
            line-height: 35pt;
            text-decoration: none;
        }
        .genButton:hover {
            background-color: #395ca7;
        }
        .genButton:active {
            position: relative;
            top: 1px;
        }
    }
}
