.login-page {
    .authorization {
        max-width: 400px;
        margin-top: 12rem;
    }

    img {
        width: 60%;
        margin: auto;
        padding-bottom: 1rem;
    }
}
