.form-button-container {
    display: flex;
    justify-content: end;
}

.form-element-container {
    padding: 1rem 5rem;
}

.powertab {
    div.menu {
        z-index: 10;
        border-bottom: 0 !important;
        margin-bottom: 0rem;
        a.active {
            z-index: 20;
            border-bottom: 0.2rem #ffa439 solid !important;
        }
        a.item {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
    }
    div.bottom {
        border: 0 !important;
    }
}
