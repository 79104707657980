@media print {
    .tablicica {
        .tableHead {
            background-color: lightgray;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
        }
    }
}

.tablicica {
    .bills {
        border-collapse: collapse;
        width: 100%;
    }

    .bills th {
        color: black;
        border: 1px solid black;
        padding: 0.2rem;
        text-align: left;
    }

    .bills th:last-of-type {
        text-align: right;
    }

    .bills td {
        border: 1px solid #ddd;
        padding: 0.2rem;
    }

    .bills tr:nth-child(even) {
        background-color: #ffffff;
    }

    .tableHead {
        background-color: lightgray;
        color: black;
    }

    .quantity {
        width: 6%;
    }

    .broj {
        width: 5%;
    }

    .bill-name {
        width: 50%;
    }

    .unit-price {
        width: 8%;
    }

    .currency {
        width: 8%;
    }

    .line-price {
        width: 10%;
        text-align: right;
    }
}
