.cData {
    p {
        padding: 0 !important;
        line-height: 1rem !important;
    }
    text-align: left;

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.5rem;

        .invoice-number {
            font-weight: bold;
        }
        .lower-part {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }
}
