.screen {
  $padding-left: 27pt;

  height: auto;

  .screen-header {
    display: flex;
    padding-left: 60px;
    padding-right: $padding-left;

    justify-content: space-between;
    .screen-right-content {
      padding: 6pt 0;
      margin: 12pt 0;
      width: auto;
    }
  }

  .screen-content {
    padding: 20pt $padding-left;

    .screen-list {
      background-color: white;
      border-radius: 7px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  hr.screen-divisor {
    margin: 0 12px;
    padding: 0;
    border: 0;
    border-top: 1px solid #eee;
  }

  h1.screen-title {
    margin: 1em 0 1em 0;
    font-weight: bold;
  }
}
