@media print {
    $font-family: 'times new roman';
    $font-size: 10pt;

    #root {
        display: none;
    }

    div.invoice-root-div {
        padding: 0;
        font-family: $font-family !important;
        font-size: 10pt;

        .language-switcher {
            display: none;
        }

        .invoice {
            padding: 0 !important;

            h1 {
                font-family: $font-family;
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }
        }

        background-color: transparent;
    }

    .invoice-root-container {
        padding: 0 !important;
        border: none !important;
    }
}

.invoice-root-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(127, 127, 127, 0.85);
    padding: 5rem 15rem;

    .language-switcher {
        width: 100%;
        .buttons {
            width: 15rem;
            margin: auto;
        }
    }

    .invoice-root-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 0;
        justify-content: space-between;
        background-color: white;
        overflow-y: auto;
        overflow-x: hidden;

        p.company-name {
            font-weight: 700;
            font-size: 1.1rem;
        }

        .invoice {
            padding: 1rem;
            display: flex;
            flex-direction: column;

            * {
                flex: 1;
            }

            .upper {
                display: flex;
                justify-content: space-between;

                p {
                    line-height: 1.6rem;
                    margin: auto;
                }
                a {
                    line-height: 1.6rem;
                }
                margin-bottom: 1rem;
            }

            .codebase {
                .log {
                    width: 14rem;
                    float: left;
                }
            }

            .sender-company-info {
                text-align: right;
                line-height: 0.5rem;
            }

            .middle-section {
                $padding: 0.33rem;

                display: flex;
                justify-items: stretch;
                border: 1px solid lightgray;

                .middle-section-item {
                    flex-grow: 1;
                    flex-basis: 0;
                    border-left: 1px solid lightgray;
                    padding: $padding;
                }

                .middle-section-item:nth-child(1) {
                    border-left: none;
                }

                .client {
                    text-align: right;
                    .card-body {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        
                        .company-name {
                            margin-bottom: 0.5rem;
                        }

                        p {
                            line-height: 0.61rem;
                        }
                    }
                }
            }

            .crt {
                width: 100%;
                margin: 1rem 0;
            }
        }

        .invoice-lower-section {
            $section-gap: 1.36rem;
            p {
                line-height: 1.35em;
                margin-bottom: 0;
            }

            .payment-and-operator {
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: 0.6rem;
                .section-header {
                    margin-top: 0.5rem;
                }
            }

            .info {
                font-size: 0.8rem;
                line-height: 1rem;
                padding-left: 0;
                margin: $section-gap 0;

                .rule {
                    display: block;
                    background: transparent;
                    width: 30%;
                    margin-right: auto;
                    margin-left: 0;
                    border: 0;
                    border-top: solid 1px black;
                }

                .foot-notes {
                    display: block;
                    padding-left: 1rem;
                    margin: 0;
                    li {
                        padding: 0;
                        font-size: 0.9rem;
                    }
                }
            }

            .footer {
                font-size: 0.8rem;
                text-align: center;
                line-height: 1rem;
            }
        }
    }
}
