table.main-table {
    $button-width: 1.35rem;

    span.error {
        color: red;

        span.subject {
            font-weight: bolder;
        }
    }

    td {
        &.text-center {
            text-align: center;
        }
        &.text-start {
            text-align: start;
        }
        &.text-end {
            text-align: end;
        }
    }

    td.actions-column {
        text-align: center;
        img {
            width: $button-width;
            aspect-ratio: 1;
            margin: 0 0.33rem;
            user-select: none;

            &:hover {
                cursor: pointer;
            }

            &:active {
                transform: translateX(1px) translateY(1px);
            }
        }
    }
}
