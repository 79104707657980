.subInvoice {
    display: flex;
    margin: 40px;
}

.dlMailPrint {
    width: 40%;
    background-color: #f8f8f8;
}

.subHead {
    background-color: #f8f8f8;
}

.submittedView {
}
