ul.power-menu {
  $left-padding: 3rem;
  $image-height: 2rem;
  $image-width: $image-height;
  $image-margin: 1.75rem;
  $nav-item-margin: 2rem;

  margin: auto;
  padding-left: $left-padding;
  list-style-type: none;

  a:link, a:visited {    
    color: #aaaaaa;
    text-decoration: none;
  }

  a:hover {
    font-weight: bold;
    color: white;
  }

  li.power-menu-item {
    text-decoration: none;
    margin-top: $nav-item-margin;
    margin-bottom: $nav-item-margin;

    font-size: 18px;

    .power-menu-link {
      text-decoration: none;
      margin: auto;
      line-height: $image-height;
      height: $image-height;
      width: 100%;
    }

    img {
      margin-right: $image-margin;
      width: $image-width;
      height: $image-height;
      vertical-align: middle;
    }
  }
  
  .selected {
    border-right: 8px #ffa439 solid;
    font-weight: bold;
    a:link, a:visited {
      color: white;
    }    
  }
}
