.invoice-summary-section {
    float: right;

    .totals-table {
        border-collapse: collapse;
        margin: auto;
        margin-right: 0;
        margin-top: 1rem;

        td {
            padding: 0.32rem 0;
        }

        tr td:first-child {
            padding-right: 6rem;
        }

        tr td:last-child {
            text-align: end;
        }

        tr.line {
            td {
                border-top: 1px solid black;
            }
        }
        tr.eur-segment {
            td {
                padding-top: 0;
                margin-top: 0;
                padding-bottom: 0;
            }
        }
        tr.top {
            td {
                padding-top: 0.8rem;
            }
        }
        tr.top-2 {
            td {
                padding-top: 1.5rem;
            }
        }
    }
}
