.dashboard {
  // background-color: rgb(245, 239, 239);

  .main-info {
    height: 250pt;
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
    padding: 50px;

    .previous-month,
    .monthly-trend {
      background-color: white;
      width: 45%;
      text-align: center;
      height: 270pt;
      font-size: 16px;
      border-radius: 10px;
    }

    .previous-month {
      .previous-heading {
        width: 100%;
        // display: relative;
        height: 20pt;
      }
      .squares1 {
        display: flex;
        margin-left: 3%;

        .invoices-amount {
          background-color: #4fa8f5;
          width: 45%;
          height: 80pt;
          margin: 10px;
          padding-top: 10px;
          border-radius: 5px;
        }

        .cleared {
          background-color: #ffa439;
          width: 45%;
          height: 80pt;
          margin: 10px;
          padding-top: 10px;
          font-size: 15px;
          border-radius: 5px;
        }
      }

      .squares2 {
        display: flex;
        margin-left: 3%;

        .amount-due {
          background-color: #ff5739;
          width: 45%;
          height: 80pt;
          margin: 10px;
          padding-top: 10px;
          font-size: 15px;
          border-radius: 5px;
        }

        .top-clients {
          background-color: #00b430;
          width: 45%;
          height: 80pt;
          margin: 10px;
          padding-top: 10px;
          font-size: 15px;
          border-radius: 5px;
        }
      }
    }
  }
  .uncleared-invoices {
    border-radius: 10px;
    margin: 10pt 53pt;
    background-color: #ffffff;
    width: 89%;
    justify-self: center;

    .uncleared-header {
      padding: 8pt;
      font-size: 17px;
      margin: 0pt 7pt 0pt;
    }

    .uncleared-invoice-displays {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 9px;

      .uncleared-left {
        display: flex;
        width: 25%;
        justify-content: space-between;
        margin-left: 20px;

        .display-invoice-number {
          align-items: center;
          justify-content: center;
        }
      }

      .uncleared-right {
        display: flex;
        width: 50%;
        justify-content: space-between;
      }
    }
  }

  .summary {
    display: flex;
    margin: 18px auto;
    width: 88%;
    line-height: 40pt;

    .summary-heading {
      width: 55%;
      font-size: 22px;
    }

    .all-sums {
      display: flex;
      justify-content: space-between;
      width: 45%;

      .summary-year,
      .summary-month,
      .summary-client {
        width: 30%;
        display: flex;
        font-size: 14px;
      }
    }
  }

  .payments {
    color: #646464;
    display: flex;
    margin: 0pt 44pt;
    height: 150pt;

    .invoice-raised,
    .payment-received,
    .overdue-payment {
      margin: 10px;
      height: 90pt;
      width: 28%;
      background-color: white;
      font-size: 20px;
      padding: 20px;
      border-radius: 10px;
      text-align: left;
    }

    .raised,
    .bag,
    .hourglass {
      float: right;
      margin-right: 10px;
    }
  }
}

.labelPrev {
  margin: 20px;
  width: 80%;
  line-height: 17pt;
  text-align: center;
}
