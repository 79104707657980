#clientTable {
  text-align: center;
  border-collapse: collapse;
  border-top: 3px solid #ddd;
  border-bottom: 3px solid #ddd;
  width: 100%;
}

#clientTable td,
#clientTable th {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

#clientTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#clientTable tr:hover {
  background-color: #ddd;
}

#clientTable th {
  padding-top: 12px;
  padding-bottom: 10px;
  text-align: center;
  background-color: white;
  color: black;
  font-size: 15px;
}

.button-container {
  padding: 1em;
}

