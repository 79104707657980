.appHeader {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main-app-container {
    display: flex;
    flex-direction: row;
    height: 100%;

    .main-app-left-navigation-pane {
        min-width: 20rem;
        background-color: #001e33;

        .navigation-pane-logo {
            box-sizing: initial;
            padding: 1.2rem;
            padding-left: 3rem;
            width: 12rem;
        }
    }

    .main-app-right-content-pane {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .main-app-user-header {
            min-height: 5rem;
            box-shadow: 0 7px 7px 0 rgba(221, 221, 221, 0.5);
        }

        .main-app-content {
            background-color: rgb(248, 248, 248);
            // max-width: 80rem;
            margin-bottom: auto;
            height: 100%;
            overflow: scroll;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}

span.empty-line {
    display: block;
    padding: 0;
    margin: 0;
    height: 2pt;
}

html,
body {
    height: 100%;
    width: 100%;
    font-size: 10pt;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: flex;
}

.appHeader {
    display: flex;
    height: 50pt;
    background-color: white;
}
