/**
* Form section
*/

form {
    label {
        display: block;
        // margin-bottom: var(--base-space-4x);

        .label-text {
            display: block;
            font-weight: bold;
            margin-bottom: var(--base-space);

            &.required {
                &::after {
                    content: ' *';
                    color: var(--staze-red);
                }
            }
        }

        .error {
            display: block;
            color: var(--staze-red);
            margin-top: var(--base-space);
        }

        input {
            width: 100%;
            border: solid 0.6px #9cb2c1;
            padding: 10px 0px;
        }
    }
}

input {
    background-color: var(--input-background-color);
    border-radius: 7px;
    color: var(--font-color);

    font: var(--font);
    font-size: 13px;

    &:active,
    &:focus {
        outline: 0;
        border-width: 2px;
        border-color: var(--staze-green);
    }
}

button,
input[type='submit'] {
    cursor: pointer;
    color: var(--font-color);
    font: var(--font);
    font-size: 13px;
    //   padding: var(--base-space) var(--base-space-2x);
    background-color: var(--button-background-color);
    border: 2px solid transparent;
    box-shadow: var(--interactive-surface-box-shadow);
    border-radius: var(--border-radius);
    font-weight: bold;

    &.__submit {
        background-color: var(--staze-green-darker);
        border-color: var(--staze-green);
        color: white;
    }

    &.__active {
        color: white;
        background-color: var(--staze-green);
        border-color: var(--staze-green);

        &:hover {
            color: white;
        }
    }

    &:hover {
        color: white;
        background-color: var(--staze-green);
        border-color: var(--staze-green-darker);
    }

    &:active {
        transform: translateY(1px);
    }

    &:focus {
        outline-style: dashed;
        outline-offset: 4px;
        outline-color: var(--staze-green);
        outline-width: 2px;
    }
}
